import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { COLORS } from "../../Constants";
import { Pagination, Select, Table, DatePicker } from "antd";
import { Link } from "react-router-dom";
import axiosConfig from "../../axiosConfig";
import { Switch } from "antd";
import moment from "moment";

function AnalysisReporting() {
  const [isLoading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [pageCount, setPageCount] = useState(15);
  const [sortFeild, setSortFeild] = useState("");
  const [sortOrder, setSortOrder] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  const [siteVal, setSiteVal] = useState("");
  const [startDate, setStartDate] = useState("");
  const [search, setSearch] = useState({
    siteCode: null,
    siteName: null,
    startDate: null,
    endDate: null,
  });

  function formatAntdDatePickerDate(selectedDate) {
    // Use the `format` function from moment.js to format the date
    return selectedDate.format("YYYY-MM-DD");
  }

  const handleSearch = (column, value) => {
    const data = { ...search };

    if (column === "startDate" || column === "endDate") {
      if (value) {
        const selectedDate = value;
        const formattedDate = formatAntdDatePickerDate(selectedDate);
        data[column] = formattedDate;
      } else {
        data[column] = null;
      }
    } else {
      data[column] = value;
    }

    setSearch(data);
  };

  const handleCustomSort = (column, currentSort) => {
    if (currentSort === "0") {
      setSortFeild(column);
      setSortOrder("1");
    } else if (currentSort === "1") {
      setSortFeild(null);
      setSortOrder(null);
    } else {
      setSortFeild(column);
      setSortOrder("0");
    }
  };
  function formatDatev2(inputDate) {
    const dateObject = new Date(inputDate);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
    const year = dateObject.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    if (inputDate) {
      return `${year}-${formattedMonth}-${formattedDay}`;
    } else {
      return null;
    }
  }
  function formatDatev1(inputDate) {
    const dateObject = new Date(inputDate);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
    const year = dateObject.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    if (inputDate) {
      return `${formattedDay}-${formattedMonth}-${year}`;
    } else {
      return null;
    }
  }

  const handleSwitch = (e) => {
    setSiteVal(e.site);
    holdUnhold(e.site);
  };

  const columns = [
    {
      title: (
        <TableHeader>
          <Top>
            <HeaderName>Analysts</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: "analystEmailID",
      key: "analystEmailID",
      className: "custom-sort-header", // Add the custom class
      width: 250,
    },
    // {
    //   title: (
    //     <TableHeader>
    //       <Top>
    //         <HeaderName>
    //           6AM - 7AM
    //         </HeaderName>
    //       </Top>
    //     </TableHeader>
    //   ),
    //   dataIndex: ['sixAMClean', 'sixAMPotential','sixAMOvernight','sixAMInvented','sixAMTotal'],
    //   key: "sixAMClean",
    //   render: (text, data) => {
    //     return (
    //       <div>
    //         <span>CP - </span>
    //         <span >
    //             {data.sixAMClean}
    //         </span>
    //         <br />
    //         <span>PP - </span>
    //         <span >
    //         {data.sixAMPotential}
    //         </span>
    //         <br />
    //         <span>OP - </span>
    //         <span >
    //         {data.sixAMOvernight}
    //         </span> <br />
    //         <span>UD - </span>
    //         <span >
    //         {data.sixAMInvented}
    //         </span> <br />
    //         <span>Total- </span>
    //         <span >
    //         {data.sixAMTotal}
    //         </span> <br />
    //       </div>
    //     );
    //   },
    // },

    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>7AM - 8AM</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "sevenAMClean",
        "sevenAMPotential",
        "sevenAMOvernight",
        "sevenAMInvented",
        "sevenAMCarReversal",
        "sevenAMTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.sevenAMClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.sevenAMPotential}</span> <br />
            <span>OP - </span>
            <span>{data.sevenAMOvernight}</span> <br />
            <span>UD - </span>
            <span>{data.sevenAMInvented}</span> <br />
            <span>CR - </span>
            <span>{data.sevenAMCarReversal}</span> <br />
            <span>Total- </span>
            <span>{data.sevenAMTotal}</span> <br />
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>8AM - 9AM</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "eightAMClean",
        "eightAMPotential",
        "eightAMOvernight",
        "eightAMInvented",
        "eightAMCarReversal",
        "eightAMTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.eightAMClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.eightAMPotential}</span> <br />
            <span>OP - </span>
            <span>{data.eightAMOvernight}</span> <br />
            <span>UD - </span>
            <span>{data.eightAMInvented}</span> <br />
            <span>CR - </span>
            <span>{data.eightAMCarReversal}</span> <br />
            <span>Total- </span>
            <span>{data.eightAMTotal}</span> <br />
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>9AM - 10AM</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "nineAMClean",
        "nineAMPotential",
        "nineAMOvernight",
        "nineAMInvented",
        "nineAMCarReversal",
        "nineAMTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.nineAMClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.nineAMPotential}</span> <br />
            <span>OP - </span>
            <span>{data.nineAMOvernight}</span> <br />
            <span>UD - </span>
            <span>{data.nineAMInvented}</span> <br />
            <span>CR - </span>
            <span>{data.nineAMCarReversal}</span> <br />
            <span>Total- </span>
            <span>{data.nineAMTotal}</span> <br />
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>10AM - 11AM</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "tenAMClean",
        "tenAMPotential",
        "tenAMOvernight",
        "tenAMInvented",
        "tenAMCarReversal",
        "tenAMTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.tenAMClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.tenAMPotential}</span> <br />
            <span>OP - </span>
            <span>{data.tenAMOvernight}</span>
            <br />
            <span>UD - </span>
            <span>{data.tenAMInvented}</span> <br />
            <span>CR - </span>
            <span>{data.tenAMCarReversal}</span> <br />
            <span>Total- </span>
            <span>{data.tenAMTotal}</span> <br />
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>11AM - 12PM</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "elevenAMClean",
        "elevenAMPotential",
        "elevenAMOvernight",
        "elevenAMInvented",
        "elevenAMCarReversal",
        "elevenAMTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.elevenAMClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.elevenAMPotential}</span> <br />
            <span>OP - </span>
            <span>{data.elevenAMOvernight}</span> <br />
            <span>UD - </span>
            <span>{data.elevenAMInvented}</span> <br />
            <span>CR - </span>
            <span>{data.elevenAMCarReversal}</span> <br />
            <span>Total- </span>
            <span>{data.elevenAMTotal}</span> <br />
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>12PM - 1PM</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "twelvePMClean",
        "twelvePMPotential",
        "twelvePMOvernight",
        "twelvePMInvented",
        "twelvePMCarReversal",
        "twelvePMTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.twelvePMClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.twelvePMPotential}</span> <br />
            <span>OP - </span>
            <span>{data.twelvePMOvernight}</span> <br />
            <span>UD - </span>
            <span>{data.twelvePMInvented}</span> <br />
            <span>CR - </span>
            <span>{data.twelvePMCarReversal}</span> <br />
            <span>Total- </span>
            <span>{data.twelvePMTotal}</span> <br />
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top>
            <HeaderName>1PM - 2PM</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "onePMClean",
        "onePMPotential",
        "onePMOvernight",
        "onePMInvented",
        "onePMCarReversal",
        "onePMTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.onePMClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.onePMPotential}</span> <br />
            <span>OP - </span>
            <span>{data.onePMOvernight}</span> <br />
            <span>UD - </span>
            <span>{data.onePMInvented}</span> <br />
            <span>CR - </span>
            <span>{data.onePMCarReversal}</span> <br />
            <span>Total- </span>
            <span>{data.onePMTotal}</span> <br />
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>2PM - 3PM</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "twoPMClean",
        "twoPMPotential",
        "twoPMOvernight",
        "twoPMInvented",
        "twoPMCarReversal",
        "twoPMTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.twoPMClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.twoPMPotential}</span>
            <br />
            <span>OP - </span>
            <span>{data.twoPMOvernight}</span>
            <br />
            <span>UD - </span>
            <span>{data.twoPMInvented}</span>
            <br />
            <span>CR - </span>
            <span>{data.twoPMCarReversal}</span>
            <br />
            <span>Total- </span>
            <span>{data.twoPMTotal}</span>
            <br />
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>3PM - 4PM</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "threePMClean",
        "threePMPotential",
        "threePMOvernight",
        "threePMInvented",
        "threePMCarReversal",
        "threePMTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.threePMClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.threePMPotential}</span> <br />
            <span>OP - </span>
            <span>{data.threePMOvernight}</span> <br />
            <span>UD - </span>
            <span>{data.threePMInvented}</span> <br />
            <span>CR - </span>
            <span>{data.threePMCarReversal}</span> <br />
            <span>Total- </span>
            <span>{data.threePMTotal}</span> <br />
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>4PM - 5PM</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "fourPMClean",
        "fourPMPotential",
        "fourPMOvernight",
        "fourPMInvented",
        "fourPMCarReversal",
        "fourPMTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.fourPMClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.fourPMPotential}</span> <br />
            <span>OP - </span>
            <span>{data.fourPMOvernight}</span> <br />
            <span>UD - </span>
            <span>{data.fourPMInvented}</span> <br />
            <span>CR - </span>
            <span>{data.fourPMCarReversal}</span> <br />
            <span>Total- </span>
            <span>{data.fourPMTotal}</span> <br />
          </div>
        );
      },
    },
    {
      title: (
        <TableHeader>
          <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
            <HeaderName>5PM - 6PM</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "fivePMClean",
        "fivePMPotential",
        "fivePMOvernight",
        "fivePMInvented",
        "fivePMCarReversal",
        "fivePMTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.fivePMClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.fivePMPotential}</span> <br />
            <span>OP - </span>
            <span>{data.fivePMOvernight}</span> <br />
            <span>UD - </span>
            <span>{data.fivePMInvented}</span> <br />
            <span>CR - </span>
            <span>{data.fivePMCarReversal}</span> <br />
            <span>Total- </span>
            <span>{data.fivePMTotal}</span> <br />
          </div>
        );
      },
    },
    //   {
    //     title: (
    //       <TableHeader>
    //         <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
    //           <HeaderName>
    //             6PM - 7PM
    //           </HeaderName>
    //         </Top>
    //       </TableHeader>
    //     ),
    //     dataIndex: ['sixPMClean','sixPMPotential','sixPMOvernight','sixPMInvented','sixPMTotal'],
    //     key: "uncleanPairCount",
    //     render: (text,data) => {
    //       return (
    //         <div>
    //         <span>CP - </span>
    //         <span >
    //             {data.sixPMClean}
    //         </span>
    //         <br />
    //         <span>PP - </span>
    //         <span >
    //         {data.sixPMPotential}
    //         </span> <br />
    //         <span>OP - </span>
    //         <span >
    //         {data.sixPMOvernight}
    //         </span> <br />
    //         <span>UD - </span>
    //         <span >
    //         {data.sixPMInvented}
    //         </span> <br />
    //         <span>Total- </span>
    //         <span >
    //         {data.sixPMTotal}
    //         </span> <br />
    //       </div>
    //       );
    //     },
    //   },
    //   {
    //     title: (
    //       <TableHeader>
    //         <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
    //           <HeaderName>
    //               7PM - 8PM
    //           </HeaderName>
    //         </Top>
    //       </TableHeader>
    //     ),
    //     dataIndex: ['sevenPMClean','sevenPMPotential','sevenPMOvernight','sevenPMInvented','sevenPMTotal'],
    //     key: "uncleanPairCount",
    //     render: (text,data) => {
    //       return (
    //         <div>
    //         <span>CP - </span>
    //         <span >
    //             {data.sevenPMClean}
    //         </span>
    //         <br />
    //         <span>PP - </span>
    //         <span >
    //         {data.sevenPMPotential}
    //         </span> <br />
    //         <span>OP - </span>
    //         <span >
    //         {data.sevenPMOvernight}
    //         </span> <br />
    //         <span>UD - </span>
    //         <span >
    //         {data.sevenPMInvented}
    //         </span> <br />
    //         <span>Total- </span>
    //         <span >
    //         {data.sevenPMTotal}
    //         </span> <br />
    //       </div>
    //       );
    //     },
    //   },
    //   {
    //     title: (
    //       <TableHeader>
    //         <Top onClick={() => handleCustomSort("userRole", sortOrder)}>
    //           <HeaderName>
    //               8PM - 9PM
    //           </HeaderName>
    //         </Top>
    //       </TableHeader>
    //     ),
    //     dataIndex: ['eightPMClean','eightPMPotential','eightPMOvernight','eightPMInvented','eightPMTotal'],
    //     key: "uncleanPairCount",
    //     render: (text,data) => {
    //       return (
    //         <div>
    //         <span>CP - </span>
    //         <span >
    //             {data.eightPMClean}
    //         </span>
    //         <br />
    //         <span>PP - </span>
    //         <span >
    //         {data.eightPMPotential}
    //         </span> <br />
    //         <span>OP - </span>
    //         <span >
    //         {data.eightPMOvernight}
    //         </span> <br />
    //         <span>UD - </span>
    //         <span >
    //         {data.eightPMInvented}
    //         </span> <br />
    //         <span>Total- </span>
    //         <span >
    //         {data.eightPMTotal}
    //         </span> <br />
    //       </div>
    //       );
    //     },
    //   },
    {
      title: (
        <TableHeader>
          <Top>
            <HeaderName>Total</HeaderName>
          </Top>
        </TableHeader>
      ),
      dataIndex: [
        "totalClean",
        "totalPotential",
        "totalOvernight",
        "totalInvented",
        "totalCarReversal",
        "totalTotal",
      ],
      key: "uncleanPairCount",
      render: (text, data) => {
        return (
          <div>
            <span>CP - </span>
            <span>{data.totalClean}</span>
            <br />
            <span>PP - </span>
            <span>{data.totalPotential}</span> <br />
            <span>OP - </span>
            <span>{data.totalOvernight}</span> <br />
            <span>UD - </span>
            <span>{data.totalInvented}</span> <br />
            <span>CR - </span>
            <span>{data.totalCarReversal}</span> <br />
            <span>Total- </span>
            <span>{data.totalTotal}</span> <br />
          </div>
        );
      },
    },
  ];

  const handlePageSizeChange = (size) => {
    setPageCount(size);
    setCurrentPage(1);
  };

  const fetchData = () => {
    setLoading(true);
    const queryParams = {
      AnalysisReportingInput: JSON.stringify({
        inputDate: startDate
          ? formatDatev2(startDate)
          : formatDatev2(defaultStartDate),
        pageNumber: currentPage,
        pageSize: pageCount,
      }),
    };
    axiosConfig
      .get(
        `GetAnalysisReportingforUI?`,

        {
          params: queryParams,
        }
      )
      .then((res) => {
        const { statusCode, data, paginationData } = res.data;
        if (statusCode === 200) {
          setdata(data);

          setPaginationData(paginationData);
        } else {
          setdata([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const holdUnhold = (site) => {
    setLoading(true);
    const queryParams = {
      site: site,
    };
    axiosConfig
      .get(
        `UpdateSiteHoldFromUI?`,

        {
          params: queryParams,
        }
      )
      .then((res) => {
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
    // getSpecificUser()
  }, [pageCount, currentPage, startDate]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.column?.key) {
      const { field, order } = sorter;
      setSortFeild(order === "ascend" || "descend" ? field : null);
      setSortOrder(order === "ascend" ? "0" : order === "descend" ? "1" : "0");
    }
  };
  const handleStartDate = (s, e) => {
    setStartDate(e);
  };

  function disabledDate(current) {
    // Disable dates greater than today or older than 6 months ago
    return (
      current &&
      (current > moment() || current < moment().subtract(6, "months"))
    );
  }
  const defaultStartDate = moment();

  return (
    <Container>
      <TopSection>
        <Title>Analysis Reporting</Title>
        <RightSection>
          <DatePicker
            format="DD-MM-YYYY"
            value={startDate}
            disabledDate={disabledDate}
            //   placeholder={formatDatev2(defaultStartDate)}
            placeholder={formatDatev1(defaultStartDate)}
            onChange={(e) => handleStartDate("startDate", e)}
          />
        </RightSection>
      </TopSection>
      <SiteListContainer>
        {" "}
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          onChange={handleTableChange}
          loading={isLoading}
          scroll={{ y: "calc(100vh - 200px)" }}
        />
        <BottomSection>
          <div>
            <span>Rows per page: </span>
            <Select
              defaultValue={pageCount}
              onChange={handlePageSizeChange}
              style={{ width: 80 }}
            >
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={15}>15</Select.Option>
              <Select.Option value={30}>30</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
          </div>
          <PaginationCover>
            <Pagination
              total={paginationData?.count}
              currentPage={currentPage}
              pageSize={pageCount}
              onChange={handlePageChange}
              current={currentPage}
            />
          </PaginationCover>
        </BottomSection>
      </SiteListContainer>
    </Container>
  );
}

export default AnalysisReporting;

const Container = styled.div`
  padding: 20px;
  .ant-pagination-options {
    display: none;
  }
  :where(.css-dev-only-do-not-override-1vr7spz).ant-picker {
    padding: 0 11px;
  }
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-column-title,
  :where(.css-dev-only-do-not-override-1vr7spz).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    font-size: 14px;
    font-weight: 500;
  }
  /* :where(.css-dev-only-do-not-override-3mqfnx).ant-table-wrapper
		.ant-table-thead
		> tr
		> th {
		position: sticky;
		top: 0;
		z-index: 1;
        min-width: 160px;
	} */
  .custom-sort-header {
    cursor: pointer;
  }
`;
const TopSection = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
`;
const Title = styled.h2`
  color: ${COLORS.THEME_COLOR};
  font-size: 18px;
  font-weight: 500;
`;
const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
const SiteListContainer = styled.div`
  margin-top: 10px;
  max-height: calc(100vh - 180px);
  min-height: calc(100vh - 180px);
  overflow: scroll;
  border: 0.5px solid #d0d1d2;
  border-radius: 10px;
  .ant-table {
    min-height: calc(100vh - 240px) !important;
    max-height: calc(100vh - 240px) !important;
    overflow: scroll;
    /* max-width: 90vw; */
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 0.5px solid #d0d1d2;
    border-collapse: collapse;
    /* background-color: #fff; */
    padding: 10px 15px;
    font-size: 12px;
  }
  .ant-table-thead > tr > th {
    font-weight: 600;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }
  .ant-pagination-item a {
    color: #000 !important;
  }
`;
const ButtonContainer = styled.div`
  display: block;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;
const Edit = styled(Link)``;
const Delete = styled.span``;
const TableHeader = styled.div`
  input {
    padding: 0 10px;
  }
`;
const HeaderName = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  white-space: nowrap;
`;
const BottomSection = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
`;
const PaginationCover = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
`;
const Sort = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(0, -5px);
  margin-left: 10px;
`;
const Icon = styled.span`
  display: block;
  height: 10px;
`;
